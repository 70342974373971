
#site-wrapper {
  position: fixed;
  top: 85px;
  height: 100%; /* Temp: Simulates a tall page. */
  z-index: 2;
}

#site-canvas {
  height: 100%;
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  -webkit-transform: translateX(0);
  transform: translateX(0);

  -moz-transform: translateZ(0) translateX(0);
  -ms-transform: translateZ(0) translateX(0);
  -o-transform: translateZ(0) translateX(0);
  -webkit-transform: translateZ(0) translateX(0);
  transform: translateZ(0) translateX(0);

  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  -moz-transition: 300ms ease all;
  -ms-transition: 300ms ease all;
  -o-transition: 300ms ease all;
  -webkit-transition: 300ms ease all;
  transition: 300ms ease all;

  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 10px;
}

.show-nav #site-canvas {
  -moz-transform: translateX(300px);
  -ms-transform: translateX(300px);
  -o-transform: translateX(300px);
  -webkit-transform: translateX(300px);
  transform: translateX(300px);

  -moz-transform: translateZ(0) translateX(300px);
  -ms-transform: translateZ(0) translateX(300px);
  -o-transform: translateZ(0) translateX(300px);
  -webkit-transform: translateZ(0) translateX(300px);
  transform: translateZ(0) translateX(300px);

  -moz-transform: translate3d(300px, 0, 0);
  -ms-transform: translate3d(300px, 0, 0);
  -o-transform: translate3d(300px, 0, 0);
  -webkit-transform: translate3d(300px, 0, 0);
  transform: translate3d(300px, 0, 0);
}

#site-menu {
  width: 300px;
  min-height: 100%;
  position: relative;
  top: 0;
  left: -300px;
  background-color: #ffffff;
  padding: 15px;
  // overflow: scroll;
  a {
    text-decoration: none;
    color: #000000;
  }
  ul {
    font-size: 20px;
    padding-left: 0;
    margin-left: 0;
    width: 100%;
    text-decoration: none;
    a {
      text-decoration: none;
      color: #000000;
      :hover, :focus, :active {
        text-decoration: none;
        background-color: #ff0000;
        color: #ffffff !important;
        width: 100%;
        height: 100%;
      }
      li {
        text-decoration: none;
        list-style-type: none;
        padding: 0 0 0 10px;
        margin-left: 0;
        line-height: 40px;
        width: 100%;
      }
    }
  }
}


@media only screen and (min-width: 100px) and (max-width: 979px) {
  #site-menu {
    width: 300px;
    min-height: 100%;
    position: relative;
    top: 0;
    left: -300px;
    background-color: #ffffff;
    padding: 10px;
    // overflow: scroll;

  }
  #site-menu a {
    text-decoration: none;
    color: #000000;
  }

  #site-menu ul {
    font-size: 14px;
    padding-left: 0;
    margin-left: 0;
    width: 100%;
    text-decoration: none;

  }

  #site-menu ul li {
    text-decoration: none;
    list-style-type: none;
    padding: 0 0 0 5px;
    margin-left: 0;
    line-height: 25px;
    width: 100%;
  }

  #site-menu ul li:hover, #site-menu ul li:focus, #site-menu ul li:active {
    text-decoration: none;
    background-color: #ff0000;
    color: #ffffff !important;
    width: 100%;
    height: 100%;
  }

  #site-menu ul li a {
    text-decoration: none;
    color: #000000;
  }

  #site-menu ul li a:hover, #site-menu ul li a:focus, #site-menu ul li a:active {
    text-decoration: none;
    color: #ffffff;
  }

}
.close-canvas-icon {
  color: #000000;
  font-size: 20px;
}

.canvas-navigation-button {
  position: absolute;
  z-index: 9999 !important;
  top: 50px;
  width: 60px;
}

#site-canvas > .canvas-navigation-button {
  left: 0;
  height: 60px;
  & > .fa {
    font-size: 40px;
    padding: 10%;
  }
}