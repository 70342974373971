// Fonts
@import url(https://s-serviceportal.de/cdn/sparkassen_webfonts/css/fonts.css);
// Variables
@import "variables";
// Bootstrap
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
body {
  overflow-x: hidden;
  font: "Sparkasse Web", sans-serif;
  font-weight: 200;
  font-size: 16px;
  font-style: normal;
  font-size-adjust: normal;
  font-stretch: normal;
  -webkit-font-smoothing: antialiased;
  line-height: 1.375;
  -webkit-overflow-scrolling: touch !important;
}

.content {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  margin-top: 20px !important;
  margin-bottom: 20px;
  background-color: white;
  // max-width: 1259 px !important;
  min-height: 90px;
  /* border: 2px solid #EAEBF0 !important;*/
}

@import "misc/misc";
@import "parts/header";
@import "parts/canvas";

@import "parts/footer";
@import "parts/slider";
@import "parts/datatable";