.navbar {
  margin-bottom: 0;
  border: none;
}

.navbar-toggle {
  margin-bottom: 0px;
  margin-top: 5px;

  left: 0;
  float: left;
  margin-left: 5px;
  .fa-bars {
    color: $navbar-default-link-color;
    font-size: 30px;
    padding: 5px;
  }
}

.navbar-form-text {
  float: left;
  width: 150px;
  height: 30px;
  margin-right: 10px;
  font-size: 0.8em;
}

.logo {
  padding: 0px 30px 0px 40px !important;
  height: 50px !important;
  margin-top: 15px;
}

.skyline-header-nav {
  margin-left: 3%;
  margin-top: 22px;
}

.navbar-login-form {
  margin-top: 25px;
  color: $navbar-default-link-color;
  input {
    float: left;
    height: 30px;
    margin-right: 10px;
  }
  input[name~="email"] {
    width: 150px;
  }
  input[name~="password"] {
    width: 150px;
  }
}