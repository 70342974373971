
.boxonheader_start {
  max-width: 50%;
// min-height: 60 %;
  top: 20%;
  left: 16%;
  right: 15%;
  position: absolute;
  padding: 15px;

}
.slider {
  max-height: 700px;
  height:700px;
  width: 100%;
  position: relative;
  z-index: 0;
}

.slider_live {
  height: 700px;
  width: 100%;
  position: relative;
  z-index: 0;
}

.slider_ls_small {
  max-height: 300px;
  width: 100%;
  position: relative;
  z-index: 0;
}

.loginbox {
  min-width: 70%;
  min-height: 40%;
  top: 15%;
  left: 15%;
  right: 15%;
  position: absolute;
  z-index: 100;
  background-image: url("/assets/bg_container.png");
  border: 1px solid #ffffff;
  padding: 15px;
}



.carousel, .item, .active {
  height: 100%;
  width: 100%;
}

.carousel-inner {
  height: 100%;
  padding: 0;
}

.fill {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.carousel-inner .item {

  -webkit-transition: opacity 2s;
  -moz-transition: opacity 2s;
  -ms-transition: opacity 2s;
  -o-transition: opacity 2s;
  transition: opacity 2s;
}

.carousel .active.left {
  left: 0;
  opacity: 0;
  z-index: 2;
}

.carousel .next {
  left: 0;
  opacity: 1;
  z-index: 1;
}

/* keep full widget on smaller screens */
@media (max-width: 767px) {
  body {
    padding-left: 0;
    padding-right: 0;
  }
  .loginbox {
    top: 5%;
    min-width: 92%;
    left: 4%;
    right: 4%;
    position: relative;

  }
  .loginbox h3 {
    font-size: 12px;
  }
  .slider_live {
    max-height: 200px;
    width: 100%;
    margin-bottom: 0;
    /*position: absolute;
    z-index:0;*/
  }

}

@media (min-width: 980px) {

  .boxonheader {
    max-width: 80%;
    // min-height: 40 %;
    top: 10%;
    left: 10%;
    right: 15%;
    position: absolute;
    padding: 15px;
    font-size: 7em !important;
    font-weight: bold !important;
    color: #ffffff !important;
    margin: 0 0 15px 0 !important;
    line-height: 1.2 !important;
    font-weight: 200 !important;
  }
}

@media only screen and (min-width: 640px) and (max-width: 979px) {

  .boxonheader {
    max-width: 80%;
    // min-height: 60 %;
    top: 10%;
    left: 10%;
    right: 15%;
    position: absolute;
    padding: 15px;
    font-size: 4em !important;
    font-weight: bold !important;
    color: #ffffff !important;
    margin: 0 0 15px 0 !important;
    line-height: 1.2 !important;
    font-weight: 200 !important;
  }

}

@media (max-width: 639px) {

  .boxonheader {
    max-width: 50%;
    // min-height: 60 %;
    top: 10%;
    left: 10%;
    right: 15%;
    position: absolute;
    padding: 15px;
    font-size: 2em !important;
    font-weight: bold !important;
    color: #ffffff !important;
    margin: 0 0 15px 0 !important;
    line-height: 1.2 !important;
    font-weight: 200 !important;
  }

}
.boxonheader_start {
  max-width: 50%;
// min-height: 60 %;
  top: 20%;
  left: 16%;
  right: 15%;
  position: absolute;
  z-index: 1;
  padding: 15px;

}