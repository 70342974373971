@import "buttons";
@import "text";

a {
  text-decoration: none !important;
}

.redhover:hover {
  color: #ff0000;
}

.tooltip-arrow {
  color: transparent;
  background-color: transparent;
}

.tooltip-inner {
  border: $navbar-default-link-color 2px solid;
}

.angleable {
  cursor: pointer;
  cursor: hand;
  &:hover {
    cursor: pointer;
    cursor: hand;
  }
}