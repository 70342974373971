
h1 {
  font-size: 40px !important;
  color: #ff0000 !important;
  margin: 0 0 15px 0 !important;
  line-height: 1.2 !important;
  font-weight: 200 !important;

  & > small {
    font-size: 18px !important;
    color: #ff0000 !important;
  }
}
h2, h3, h4 {
  margin: 0 0 15px 0 !important;
  color: #ff0000 !important;

  & > small {
    color: #ff0000 !important;
  }
}
h6 {
  font-size: 18px !important;
  color: #ff0000 !important;
  margin: 15px 0 15px 0 !important;
  line-height: 1.2 !important;
  font-weight: bold !important;
}