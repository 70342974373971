.btn-footer {
  color: #ffffff;
  background-color: #787878 !important;
  border-color: #787878 !important;
  border: 0 !important;
  margin-bottom: 10px;
}

.btn-simk {
  color: #ffffff;
  background-color: #ff0000;
  border-color: #d43f3a;
}
.btn-margin{
  margin-bottom: 10px;
}