/*Gestaltung des unten fixierten Footers*/
footer {
  max-height: 80px !important;
  margin-top: 30px;
  background-color: #787878 !important;
}

.navbar-fixed-bottom {
  background-color: #787878 !important;

  min-height: 60px !important;
  bottom: 0;
}

.footer-text {
  color: #ffffff;
  font-size: 14px;
  padding-top: 10px;
}

.footer-text a {
  color: #ffffff;
}

.footer-div {
  width: auto;
  padding-left: 10px;
  padding-top: 10px;
  float: left;
}
.footer-menu-entries{
  padding-left:0px;
}